import Vue from 'vue'
import { List, Toast, Popup, Swipe, SwipeItem, Field, PullRefresh, RadioGroup, Radio, CheckboxGroup, Checkbox, Loading } from 'vant'
import 'vant/lib/list/style'
import 'vant/lib/field/style'
import 'vant/lib/toast/style'
import 'vant/lib/popup/style'
import 'vant/lib/swipe/style'
import 'vant/lib/loading/style'
import 'vant/lib/swipe-item/style'
import 'vant/lib/pull-refresh/style'
import 'vant/lib/checkbox/style'
import 'vant/lib/checkbox-group/style'
import 'vant/lib/radio/style'
import 'vant/lib/radio-group/style'
Vue.use(Swipe)
Vue.use(SwipeItem)
Vue.use(List)
Vue.use(Toast)
Vue.use(Popup)
Vue.use(Field)
Vue.use(PullRefresh)
Vue.use(RadioGroup)
Vue.use(Radio)
Vue.use(CheckboxGroup)
Vue.use(Checkbox)
Vue.use(Loading)
